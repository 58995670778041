<template>
  <div class="maintenance">

    <light-box :images="images" :index="index" @close="index = null" />

    <div class="page-title">
      <div class="content-container">
        <h1 class="title is-4">
          Maintenance
        </h1>
      </div>
    </div>

    <div class="content-container page-text">

      <p>We pride ourselves on having a team of expertly trained tradespeople who understand our client’s requirements. We understand the importance of industry up time and aim to deliver the best quality service available.</p>

      <p>Our maintenance services include:</p>

      <div class="content">
        <ul>
          <li>Preventative maintenance</li>
          <li>24 hour breakdown maintenance</li>
          <li>Shut down maintenance</li>
        </ul>
      </div>

      <div class="columns margin-top-20">
        <div class="column">
          <img :src="images[0]" width="100%" class="shadow pad pointer" @click="index = 0" />
        </div>
        <div class="column">
          <img :src="images[1]" width="100%" class="shadow pad pointer" @click="index = 1" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      index: null,
      images: [
        require('@/assets/img/maintenance/1.jpg'),
        require('@/assets/img/maintenance/2.jpg')
      ]
    }
  }
}
</script>
